export const textBlock = [
  {
    title: 'Plataforma digital exclusiva para o RH',
    description: 'Total facilidade para a gestão do benefício. Inclusão/exclusão de colaboradores, acompanhamento dos fundos do plano, ajuste das contribuições, uso do saldo global e outras funcionalidades que otimizam todo o processo.'
  },
  {
    title: 'Portabilidade dos sonhos',
    description: 'Mudar seu plano de previdência de uma seguradora para outra ficou mais simples, rápido e digital. Finalmente chegou uma solução sem dor de cabeça, discussões e formulários intermináveis.'
  },
  {
    title: 'Atendimento de verdade',
    description: 'No app, temos um chat com especialistas da Onze à disposição para tirar todas as dúvidas de seus colaboradores em tempo real, sem chatbot ou robôs. Nosso time assume as dúvidas e auxilia seus colaboradores, liberando tempo do time de RH.'
  },
]