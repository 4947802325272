import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'

const cards = [
  {
    title: 'Atração de talentos estratégicos',
    description: '80% dos colaboradores trocariam de trabalho por uma empresa que cuide da sua saúde financeira (Pesquisa Onze, 2023). '
  },
  {
    title: 'Retenção e engajamento',
    description: 'Colaboradores com previdência ficam, em média, 2 anos a mais na empresa do que profissionais sem cuidados de saúde financeira'
  },
  {
    title: 'Benefícios fiscais',
    description: 'Além do colaborador, a empresa pode ter incentivos fiscais que só a previdência privada oferece'
  },
  {
    title: 'RH mais estratégico',
    description: 'Com uma plataforma 100% digital, o RH não perde tempo operacional na gestão do benefício'
  },
]


const Benefits = () => {

  return (
    <section className="company-benefits container">
      <div className="company-benefits__description">
        <Typography as="h2" variant="headline4" className="company-benefits__description-title">
          Um benefício de alto impacto que é tendência mundial
        </Typography>

        <Button
          as="a"
          href=""
          label="Quero falar com a Onze"
          iconId="right-arrow"
          iconPosition="right"
          buttonStyle="outline"
          customClass="section-financial-health__button"
        />
      </div>

      <div className="company-benefits__cards">
        {cards.map(({ title, description }, index) => (
          <div className="company-benefits__card" key={`${title}-${index}`}>
            <Typography as="p" variant="subtitle" className="company-benefits__card-title" weight="bold">
              {title}
            </Typography>
            <Typography as="p" variant="body1" className="company-benefits__card-description">
              {description}
            </Typography>
          </div>
        ))}
      </div>
    </section>
  )
}


export default Benefits