import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'
import Img from 'components/Img'

import Routes from 'Routes'

const { safety } = Routes

const SectionSafetyFirst = () => {
  return (
    <section className="section-safety-first">
      <div className="container section-safety-first__content">
        <div className="section-safety-first__text">
          <Typography
            as="h4"
            variant="headline4"
            className="section-safety-first__title">
            Segurança em 1º lugar
          </Typography>
          <div className="section-safety-first__text-body">
            <Typography
              as="p"
              variant="body1"
              className="section-safety-first__description">
              O dinheiro do seu time está garantido pelas principais seguradoras independentes do país: Icatu e Zurich.
              <br />
              <br />
              Além disso, somos regulados pelas entidades mais importantes do mercado de investimentos e de seguros.
            </Typography>
          </div>

          <Button
            as="a"
            href={safety.path}
            label="Saiba mais"
            iconId="right-arrow"
            iconPosition="right"
            buttonStyle="text"
            customClass="section-safety-first__button"
          />
        </div>

        <div className="section-safety-first__image">
          <Img
            className="section-safety-first__image-safe-box"
            src="/insurer-security02.png"
            alt="Segurança"
          />
        </div>
      </div>
    </section>
  )
}

export default SectionSafetyFirst
