import Typography from 'rv-components-onze/src/Typography'

const CardMissingPush = ({ title, children }) => {
  return (
    <div className="card-missing-push">
      <Typography
        as="h4"
        variant="subtitle"
        className="card-missing-push__title"
        weight="bold"
      >
        {title}
      </Typography>

      <div className="card-missing-push__line" />

      <Typography
        as="p"
        variant="body1"
        className="card-missing-push__content"
      >
        {children}
      </Typography>
    </div>
  );
}

export default CardMissingPush