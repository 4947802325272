export const cardsList = [
  {
    title: 'Previdência 100% digital',
    description:
      'Por meio do aplicativo, seu colaborador faz a adesão, gestão e portabilidade do plano de previdência. Sem papel ou burocracia.',
  },
  {
    title: 'Check-up e consultorias',
    description:
      'Mesmo sem investir na previdência, seu colaborador pode melhorar sua saúde financeira por meio de check-ups no aplicativo e consultas com especialistas.',
  },
  {
    title: 'Palestras e incentivos de adesão',
    description:
      'Sua empresa terá acesso a palestras in company sobre Educação Financeira e seus colaboradores incentivos financeiros recorrentes.',
  },
]
