import Header from 'components/Header'
import Menu from 'components/Menu'
import OurWallet from 'components/OurWalletSection'
import Hero from './components/Hero'
import SectionFinancialHealth from 'components/SectionFinancialHealth'
import SectionSafetyFirst from 'components/SectionSafetyFirst'
import SectionMissingPush from './components/SectionMissingPush'
import DreamBenefits from 'components/DreamBenefits'
import Steps from './components/Steps'
import Benefits from './components/Benefits'
import SectionForm from 'components/SectionForm'
import Sidebar from 'components/Sidebar'
import FooterNew from 'components/FooterNew'

import { textBlock } from './mock'

import enhancer from './hooks'

const ForYourCompany = () => {
  return (
    <div className="your-company">
      <Header />
      <Menu />
      <Sidebar>
        <SectionForm customClass="sidebar" />
      </Sidebar>
      <Hero />
      <Benefits />
      <SectionMissingPush />
      <DreamBenefits 
        imageSrc="/rebranding-simples-aderir-img.png"
        title="Simples de aderir, simples de gerir"
        bulletList={textBlock}
      />
      <Steps />
      <OurWallet />
      <SectionSafetyFirst />
      <FooterNew />
    </div>
  )
}

export default enhancer(ForYourCompany)
