import Typography from 'rv-components-onze/src/Typography'

import Card from '../CardMissingPush'
import { cardsList } from './mock'

const SectionMissingPush = () => {
  return (
    <section className="section-missing-push">
      <div className="container section-missing-push__content">
        <div className="section-missing-push__header">
          <Typography
            as="h2"
            variant="headline5"
            className="section-missing-push__title">
            Liberdade {'&'} Saúde {'&'} Educação Financeira aos seus
            colaboradores
          </Typography>
          <Typography
            as="p"
            variant="body1"
            className="section-missing-push__subtitle">
            A saúde financeira do seu colaborador impacta diretamente na saúde
            da sua empresa, e a Onze surgiu para melhorar a relação do seu
            funcionário com as finanças, por meio de um app simples, intuitivo e
            digital.
          </Typography>
        </div>
        <div className="section-missing-push__body">
          {cardsList.map(({ title, description }, index) => (
            <Card key={`card-${index}`} title={title}>
              {description}
            </Card>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SectionMissingPush


