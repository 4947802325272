import { useEffect } from 'react'
import animationObserver from 'utils/animationObserver'

const useSnimationObserver = () => {

  useEffect(() => {
    animationObserver()
  }, [])
}

export default useSnimationObserver
