import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'
import Icon from 'rv-components-onze/src/Icon'
import Img from 'components/Img'

import Routes from 'Routes'

const { financialHealth } = Routes

const SectionFinancialHealth = () => {
  return (
    <section className="section-financial-health">
      <div className="container">
        <div className="section-financial-health__block">
          <div className="section-financial-health__info">
            <Typography
              as="h3"
              variant="headline4"
              className="section-financial-health__title">
              Mais do que educação, saúde financeira.
            </Typography>
            <Typography
              as="p"
              variant="body1"
              className="section-financial-health__subtitle">
              A Onze vai além da previdência e traz uma solução completa para a
              construção do patrimônio da sua equipe.
            </Typography>

            <Button
              as="a"
              href={financialHealth.path}
              label="Conheça nosso hub de Saúde Financeira"
              iconId="right-arrow"
              iconPosition="right"
              buttonStyle="outline"
              customClass="section-financial-health__button"
            />
          </div>
          <div className="section-financial-health__image-box--desktop">
            <Img
              className="section-financial-health__image"
              src="/rebranding-financial-health-img.jpeg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="section-financial-health__image-box--mobile">
        <Img
          className="section-financial-health__image"
          src="/rebranding-financial-health-img.jpeg"
          alt=""
        />
      </div>

      <div className="container section-financial-health__list">
        <a
          href={financialHealth.path}
          className="section-financial-health__item">
          <div className="section-financial-health__icon">
            <Icon iconId="money" />
          </div>
          <Typography
            as="p"
            variant="body1"
            className="section-financial-health__item--text">
            Check-ups <b>periódicos</b> da saúde financeira dos colaboradores
          </Typography>
        </a>
        <a
          href={financialHealth.path}
          className="section-financial-health__item">
          <div className="section-financial-health__icon">
            <Icon iconId="people" />
          </div>
          <Typography
            as="p"
            variant="body1"
            className="section-financial-health__item--text">
            Consultas <b>individuais</b> com <b>especialistas</b> financeiros
          </Typography>
        </a>
        <a
          href={financialHealth.path}
          className="section-financial-health__item">
          <div className="section-financial-health__icon">
            <Icon iconId="play" />
          </div>
          <Typography
            as="p"
            variant="body1"
            className="section-financial-health__item--text">
            Palestras educativas sobre <b>educação</b> e <b>saúde financeira</b>
          </Typography>
        </a>
      </div>
    </section>
  )
}

export default SectionFinancialHealth
